// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Container, Label } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../redux-store/loader';
import { openToast } from '../../../redux-store/toast';

// Models
import SequenceModel, { EmptySequence, NewSequenceFolderInterface, NewSequenceInterface, SequenceFolderInterface, SequenceInterface } from '../../../models/sequence';

// Components
import BottomNav from '../../components/bottomNav/BottomNav';
import CloneSequenceModalBody from './components/cloneSequenceModalBody';
import CustomList, { ItemActionInterface, ItemInterface } from '../../components/list';
import GenericModal, { ClosedModal, GenericModalProps } from '../../components/genericModal';
import OButton from '../../styled/button';
import PanelNav from '../../components/nav/PanelNav';
import Submenu from '../../components/nav/Submenu';
import ImageViewer from '../../components/imageViewer';
import SequenceFolderList from './components/sequenceFolderList';
import EditFolderModalBody from './components/editFolderModalBody';
import FoldersBreadcrumb from './components/FoldersBreadcrumb';
import CustomToggle from './components/CustomToggle';

// Styled components
import { Wrapper } from '../../styled/wrappers';

// Locales
import I18n from '../../../i18n';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
};

interface SequencesProps extends MapDispatchProps {
    navigate?: any;
    search?: any
};

interface SequenceListItem extends SequenceInterface {
    selected?: boolean;
}

interface SequencesState {
    filteredSequences: SequenceListItem[];
    filterValue: string;
    itemFns: ItemActionInterface[];
    itemComponents: { render: (item: ItemInterface) => JSX.Element }[];
    modal: GenericModalProps;
    sequences: SequenceInterface[];
    sequenceList: ItemInterface[];
    sequenceImageSrc: string | null | undefined;

    folderId: string
    folder: SequenceFolderInterface | null

    folders: SequenceFolderInterface[]
};

class Sequences extends React.Component<SequencesProps, SequencesState> {

    constructor(props: SequencesProps) {

        super(props);

        this.state = {
            filteredSequences: [],
            filterValue: '',
            itemFns: [{
                callback: this.onCloneSequence,
                icon: 'copy outline'
            }],
            itemComponents :[
                {
                    render: (item: ItemInterface) => <Label basic circular active color={ item.extraData.published ? 'green': 'red' } title={ item.extraData.isReadyToPublish ? item.extraData.published ? I18n.t('sequences.published') : I18n.t('sequences.draft'): I18n.t('sequences.draftNotReady')}><CustomToggle checked={item.extraData.published} disabled={!item.extraData.isReadyToPublish} onChange={()=> this.onPublish(item._id)}/></Label>,
                },
                {
                    render: (item: ItemInterface) => item.extraData.usersCount !== undefined ? <Label basic circular active color='teal'>{I18n.t('sequences.students')}: { item.extraData.usersCount }&nbsp;</Label> : <></>,
                },
                { 
                    render: (item: ItemInterface) => item.extraData.groupsCount !== undefined ? <Label basic circular active color='teal'>{I18n.t('sequences.groups')}: { item.extraData.groupsCount }&nbsp;</Label> : <></>,
                }
            ],
            modal: ClosedModal,
            sequences: [],
            sequenceList: [],
            sequenceImageSrc: null,

            folderId: this.props.search.get('folderId') || 'none',
            folder: null,

            folders: [],
        };

    }

    componentDidMount() {

        this.getData();

    }

    componentDidUpdate() {
        const folderId = this.props.search.get('folderId') || 'none';

        if (this.state.folderId !== folderId) {
            this.setState({ folderId });
        }

    }

    dataToList = (list: any[], selectable: boolean): ItemInterface[] => {

        const returnedList: ItemInterface[] = [];
        _.each(list, item => {

            returnedList.push({
                _id: item._id,
                selectable,
                selected: !!item?.selected,
                pictureUrl: item?.thumbUrl,
                header: item?.title?.ES?.toUpperCase(),
                description: _.size(item?.routine) + ' ' + I18n.t('sequences.exercises'),
                extraData: {
                    published: item?.published || false,
                    usersCount: item?.usersCount,
                    groupsCount: item?.groupsCount,
                    isReadyToPublish: this.isReadyToPublish(item)
                },
                draggable: true
            });

        });

        return returnedList;

    };

    getData = async () => {

        this.props.openLoader();

        const folderId = this.props.search.get('folderId') || 'none';

        const { sequences, folders, ...rest } = await SequenceModel.getSequenceFolder(folderId);

        const filteredSequences = _.orderBy(sequences, 'name', 'desc');
        const sequenceList = this.dataToList(filteredSequences, false);

        this.setState({ filteredSequences, sequences, sequenceList, folders, folder: rest });

        this.props.closeLoader();

    };

    onCloneSequence = (item: ItemInterface) => {

        const { closeLoader, openLoader } = this.props;

        const cloneSequence = async (newSequence: NewSequenceInterface | SequenceInterface) => {

            this.closeModal();

            openLoader();

            try {

                const sequenceCreated = await SequenceModel.createSequence(newSequence);
                await SequenceModel.updateSequence(sequenceCreated._id, newSequence as SequenceInterface);
                this.getData();

            } catch (error) {

                this.setState({ modal: {
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                }});

            }

            closeLoader();
        };

        const { sequences } = this.state;
        const baseSequence: NewSequenceInterface | SequenceInterface = _.find(sequences, sequence => sequence._id === item._id) || EmptySequence;

        this.setState({ modal: {
            open: true,
            title: I18n.t('buttons.actions.clone'),
            renderBody: () => <><span>{ I18n.t('sequences.cloneSequenceModalText') }</span><CloneSequenceModalBody baseSequence={ baseSequence } onSubmit={ cloneSequence } /></>
        }});

    };

    onFilter = (filterValue: string) => {

        let filteredSequences = [ ...this.state.sequences ];
        let sequenceList = [ ...this.state.sequenceList ];

        if (filterValue) {

            filteredSequences = _.filter(filteredSequences, sequence => sequence?.title?.ES?.toUpperCase().includes(filterValue.toUpperCase()))
            sequenceList = this.dataToList(filteredSequences, false);

        }

        this.setState({ filteredSequences, filterValue, sequenceList })

    }

    reloadPage = () =>{
        const folderId = this.state.folderId;
        this.props.navigate(`/sequences${folderId !== 'none'? `?folderId=${ folderId }`: ''}`);
    }

    isReadyToPublish = (sequence: SequenceInterface): boolean => {

        return !_.isEmpty(sequence?.title) &&
            !_.isEmpty(sequence?.description) &&
            !_.isEmpty(sequence?.level) &&
            !_.isEmpty(sequence?.routine);
    
    };

    onPublish = async (sequenceId: string) => {

        const { openLoader, closeLoader } = this.props;

        openLoader();
        try {

            await SequenceModel.publishSequence('' + sequenceId);
            this.reloadPage();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });

        } finally {

            closeLoader();

        }

    };


    onCreateFolder = () => {

        const { closeLoader, openLoader } = this.props;

        const createFolder = async (newFolder: NewSequenceFolderInterface | SequenceFolderInterface) => {

            this.closeModal();

            openLoader();
            
            try {

                await SequenceModel.createSequenceFolder(newFolder);
                this.getData();

            } catch (error) {

                this.setState({ modal: {
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                }});

            }

            closeLoader();
        };

        this.setState({ modal: {
            open: true,
            title: I18n.t('buttons.actions.createFolder'),
            renderBody: () => <EditFolderModalBody onSubmit={ createFolder } />
        }});

    }

    onEditFolder = (folder: SequenceFolderInterface) => {

        const { closeLoader, openLoader } = this.props;

        const editFolder = async (editedFolder: NewSequenceFolderInterface | SequenceFolderInterface) => {

            this.closeModal();

            openLoader();
            
            try {

                await SequenceModel.updateSequenceFolder(folder._id, editedFolder as SequenceFolderInterface);
                this.getData();

            } catch (error) {

                this.setState({ modal: {
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                }});

            }

            closeLoader();
        };

        this.setState({ modal: {
            open: true,
            title: I18n.t('buttons.actions.editFolder'),
            renderBody: () => <EditFolderModalBody folder={ folder } onSubmit={ editFolder } />
        }});

    }

    closeModal = () => this.setState({ modal: ClosedModal });

    onDragStart = (e: React.DragEvent<HTMLDivElement>, itemId: string) => {
        e.dataTransfer.setData('text/plain', itemId);
    }

    onMoveSequence = async (folderId: string, sequenceId: string) => {

        if(!folderId || !sequenceId) return;
         
        const { closeLoader, openLoader } = this.props;

        openLoader();
        
        try {

            const sequence = this.state.sequences.find(sequence => sequence._id === sequenceId);

            if(!sequence) return;

            const updatedSequence = _.cloneDeep(sequence);

            updatedSequence.folderId = folderId;

            await SequenceModel.updateSequence(sequenceId, updatedSequence);
            await this.getData();

        } catch (error) {

            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        }

        closeLoader();

    }

    render() {

        const { navigate } = this.props;
        const { filterValue, itemFns, itemComponents, modal, sequenceList, sequenceImageSrc, folders } = this.state;

        return (
            <Wrapper>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='sequences' />
                <Container style={{ paddingTop: '20px' }}>
                    <Submenu
                        filterFn={ this.onFilter }
                        filterValue={ filterValue }
                        mainAction={{ title: I18n.t('sequences.newSequence'), callback: () => navigate(`/sequences/new${this.state.folderId !== 'none' ? `?folderId=${this.state.folderId}` : ''}`) }}
                        title={ I18n.t('menu.sequences') }
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            {
                                this.state.folder && <FoldersBreadcrumb folder={this.state.folder} />
                            }
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <SequenceFolderList
                                            items={ folders }
                                            onCreateFolderFn={ this.onCreateFolder }
                                            onEditFolderFn={ this.onEditFolder }
                                            onMoveSequenceFn={ this.onMoveSequence }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='p-content' style={{ marginTop: '20px' }}>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <CustomList
                                            items={ sequenceList }
                                            itemFns={ itemFns }
                                            itemComponents={itemComponents}
                                            onClickItemFn={ (item: ItemInterface) => navigate(`/sequences/edit/${ item._id }`) }
                                            onClickItemSpanFn={ (item: ItemInterface)=>  this.setState({ sequenceImageSrc: item.pictureUrl }) }
                                            onDragStart={ this.onDragStart }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='sequences' />
                <ImageViewer open={ sequenceImageSrc !== null } title={ I18n.t('sequences.image') } src={ sequenceImageSrc } onClose={ () => this.setState({ sequenceImageSrc: null }) } />
            </Wrapper>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(Sequences);