// Third party libraries
import { Icon, List } from 'semantic-ui-react';
import { useSearchParams } from 'react-router-dom';

// Models
import { SequenceFolderInterface } from '../../../../../models/sequence';

// Styles
import './index.scss';

// Locales
import I18n from '../../../../../i18n';

type SequenceFolderListProps = {
  items: SequenceFolderInterface[];
  onCreateFolderFn?: () => void;
  onEditFolderFn?: (folder: SequenceFolderInterface) => void;
  onMoveSequenceFn?: (folderId: string, sequenceId: string) => void;
};

const SequenceFolderList = ({
  items,
  onCreateFolderFn,
  onEditFolderFn,
  onMoveSequenceFn
}: SequenceFolderListProps) => {
  const [, setUrlSearchParams] = useSearchParams();

  const handleOnClick = (folderId: string) => {
    setUrlSearchParams({ folderId });
  };

  const onDragOver = (e: React.DragEvent) => { 
    e.preventDefault(); 
    // Necesario para permitir el drop 
  }; 
  
  const onDrop = (e: React.DragEvent, itemId: string) => { 
    e.preventDefault(); 
    const draggedElementId = e.dataTransfer.getData('text/plain'); 
    if(onMoveSequenceFn) onMoveSequenceFn(itemId, draggedElementId);
  };

  const renderItem = (item: SequenceFolderInterface) => (
    <List.Item
      key={item._id}
      className="folderListItem"
      onClick={() => handleOnClick(item._id)}
      onDragOver={onDragOver}
      onDrop={(e: React.DragEvent) => onDrop(e, item._id)}
    >
      <div className="folderListItemContent">
        <Icon name="folder" size="large" />
        <p>{item.name}</p>
        <button
          className="folderListItemButton"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onEditFolderFn && onEditFolderFn(item)
          }}
        >
          <Icon name="edit" size="small" color='black'/>
        </button>
      </div>
    </List.Item>
  );

  return (
    <List selection horizontal>
      {items.map((item) => renderItem(item))}
      <List.Item
        key="creator"
        className="folderListItem"
        onClick={onCreateFolderFn}
      >
        <div
          className="folderListItemContent"
          title={I18n.t('buttons.actions.createFolder')}
        >
          <Icon name="add" size="large" color='grey'/>
        </div>
      </List.Item>
    </List>
  );
};

export default SequenceFolderList;
