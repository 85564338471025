import _ from 'lodash';

import { GroupSequenceInterface } from './group';
import { StudentSequenceInterface } from './student';

import AxiosService from '../utils/AxiosService';

import Config from '../config';

export interface SequenceCategoryInterface {
    label: string;
    _id: string;
};

export interface RoutineItemInterface {
    code: string;
    duration: number;
};

interface LocaleInterface {
    ES: string;
};

export interface NewSequenceInterface {
    categories: string[];
    description: LocaleInterface;
    level: string;
    title: LocaleInterface;
    folderId?: string;
};

export interface SequenceInterface extends NewSequenceInterface {
    _id: string;
    asanasCount: number;
    createdAt: string;
    thumbUrl?: string;
    published: boolean;
    routine: RoutineItemInterface[];
    targets: string[];
    types: string[];
    updatedBy?: string;
    videoUrl?: string;
    usersCount?: number;
    groupsCount?: number;
};

export const EmptySequence: SequenceInterface = {
    categories: [],
    description: { ES: '' },
    level: '',
    title: { ES: '' },
    _id: '',
    asanasCount: 0,
    createdAt: '',
    published: false,
    routine: [],
    targets: [],
    types: [],
    folderId: 'none'
};

export interface SequenceFolderInterface {
    _id: string,
    proUserId: string,
    code: string,
    createdAt: string,
    parentId: string,
    createdBy: string,
    name: string
}

export interface NewSequenceFolderInterface {
    name: string;
    parentId: string;
};

export interface AsanaInterface {
    _id: string;
    code: string;
    compatibleAsanas?: string[];
    description: LocaleInterface,
    image: string;
    isAdvanced: boolean;
    isBeginner: boolean;
    name: LocaleInterface;
    nameAudio: {
        NES: string;
        SES: string;
    },
    technicalAudio: LocaleInterface,
    updatedAt?: string;
    updatedBy?: string;
    video: string;
};

const assignToGroup = async (id: string, groupId: string): Promise<void> => _.get(await AxiosService.put(Config.server.api.sessionsApi + 'pro/sequence/' + id + '/group/' + groupId, {}, true), 'data', {});
const assignToStudent = async (id: string, studentId: string): Promise<void> => _.get(await AxiosService.put(Config.server.api.sessionsApi + 'pro/sequence/' + id + '/user/' + studentId, {}, true), 'data', {});
const createSequence = async (newSequence: NewSequenceInterface): Promise<SequenceInterface> => _.get(await AxiosService.post(Config.server.api.sessionsApi + 'pro/sequence', newSequence, true), 'data', {});
const getAsanas = async (): Promise<AsanaInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/asana', true), 'data', []);
const getSequence = async (id: string): Promise<SequenceInterface> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/sequence/' + id, true), 'data', {});
const getSequenceCategories = async (): Promise<SequenceCategoryInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'sequenceCategories', true), 'data', []);
const getSequences = async (): Promise<SequenceInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/sequence', true), 'data', []);
const getSequenceFolder = async (parentId: string = 'none'): Promise<{ sequences: SequenceInterface[], folders: SequenceFolderInterface[] } & SequenceFolderInterface> => _.get(await AxiosService.get(Config.server.api.sessionsApi + `pro/sequenceFolder/${parentId}`, true), 'data', {});
const getSequenceGroups = async (id: string): Promise<GroupSequenceInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/sequence/' + id + '/group', true), 'data', []);
const getSequenceStudents = async (id: string): Promise<StudentSequenceInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/sequence/' + id + '/user', true), 'data', []);
const publishSequence = async (id: string): Promise<void> => _.get(await AxiosService.put(Config.server.api.sessionsApi + 'pro/sequence/' + id + '/publish', {}, true), 'data', {});
const removeSequence = async (id: string): Promise<void> => _.get(await AxiosService.deleting(Config.server.api.sessionsApi + 'pro/sequence/' + id, true), 'data', {});
const updateSequence = async (id: string, sequence: SequenceInterface): Promise<void> => _.get(await AxiosService.put(Config.server.api.sessionsApi + 'pro/sequence/' + id, sequence, true), 'data', {});
const createSequenceFolder = async (newFolder: NewSequenceFolderInterface): Promise<void> => _.get(await AxiosService.post(Config.server.api.sessionsApi + 'pro/sequenceFolder', newFolder, true), 'data', {});
const updateSequenceFolder = async (folderId: string, folder: SequenceFolderInterface): Promise<void> => _.get(await AxiosService.put(Config.server.api.sessionsApi + 'pro/sequenceFolder/' + folderId, folder, true), 'data', {});
const getSequenceFolderPath = async (parentId: string = 'none'): Promise<SequenceFolderInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/sequenceFolderPath/' + parentId, true), 'data', []);

const exports = {
    assignToGroup,
    assignToStudent,
    createSequence,
    getAsanas,
    getSequence,
    getSequenceCategories,
    getSequences,
    getSequenceFolder,
    getSequenceGroups,
    getSequenceStudents,
    publishSequence,
    removeSequence,
    updateSequence,
    createSequenceFolder,
    updateSequenceFolder,
    getSequenceFolderPath
};

export default exports;